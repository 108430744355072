<template>
  <div>
    <v-card class="ma-1 pa-1">
      <v-card-title class="pa-1">
        <span>{{ $t('position.index') }}</span>
        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="mr-2"
          style="width:100px !important;"
          :placeholder="$t('search')"
          @keyup.native.enter="getList"
          outlined
          dense
          single-line
          hide-details
        ></v-text-field>

        <v-btn
          v-if="$store.getters.checkPermission('position-create')"
          color="#6ac82d"
          x-small
          dark
          fab
          @click="newItem"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        dense
        fixed-header
        :loading-text="$t('loadingText')"
        :no-data-text="$t('noDataText')"
        :height="screenHeight"
        :loading="loading"
        :headers="headers"
        item-key="id"
        :items="items"
        class="ma-1"
        style="border: 1px solid #aaa;"
        :server-items-length="server_items_length"
        :options.sync="dataTableOptions"
        :disable-pagination="true"
        :footer-props="{
          itemsPerPageOptions: [ 20, 50, 100],
          itemsPerPageAllText:$t('itemsPerPageAllText'),
          itemsPerPageText: $t('itemsPerPageText'),
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right'
        }"
        @update:page="updatePage"
        @update:items-per-page="updatePerPage"
      >
        <template
          v-slot:item.id="{ item }"
        >{{items.map(function(x) {return x.id; }).indexOf(item.id) + 1}}</template>

        <template v-slot:item.company_id="{ item }">{{ item.company['name'] }}</template>
        <template v-slot:item.position_type_id="{ item }">
          <span
            style="white-space:normal;max-width: 100px;"
          >{{ item.position_type['name_'+$i18n.locale] }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="$store.getters.checkPermission('position-update')"
            color="blue"
            small
            text
            @click="editItem(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="$store.getters.checkPermission('position-delete')"
            color="red"
            small
            text
            @click="deleteItem(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialog" @keydown.esc="dialog = false" persistent max-width="600px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          <span class="headline">{{ dialogHeaderText }}</span>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined x-small fab class @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form @keyup.native.enter="save" ref="dialogForm">
            <v-row>
              <v-col cols="12">
                <label for>{{ $t('company_id') }}</label>
                <v-autocomplete
                  clearable
                  v-model="form.company_id"
                  :items="companies"
                  :rules="[v => !!v || $t('input.required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-autocomplete>
              </v-col>
              <!-- <v-col cols="12">
                <label for>{{ $t('positionType.index') }}</label>
                <v-autocomplete
                  clearable
                  v-model="form.position_type_id"
                  :items="position_types"
                  :rules="[v => !!v || $t('input.required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-autocomplete>
              </v-col> -->
              <v-col cols="12">
                <label for>{{ $t('code') }}</label>
                <v-text-field
                  v-model="form.code"
                  :rules="[v => !!v || $t('input_required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="inputs-position">
                <label for>{{ $t('name_uz_latin') }}</label>
                <v-text-field
                  v-model="form.name_uz_latin"
                  :rules="[v => !!v || $t('input_required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-text-field>
                <div @click="handleInputs" class="buts-reload">
                  <img :src="require('@/assets/reload1.png')" width="35"/>
                </div>
                </v-col>
              <v-col cols="12">
                <label for>{{ $t('name_uz_cyril') }}</label>
                <v-text-field
                  v-model="form.name_uz_cyril"
                  :rules="[v => !!v || $t('input_required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label for>{{ $t('name_ru') }}</label>
                <v-text-field
                  v-model="form.name_ru"
                  :rules="[v => !!v || $t('input_required')]"
                  hide-details="auto"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <small color="red">{{ $t('input_required') }}</small>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="save">{{ $t('save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loading" width="300" hide-overlay>
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t('loadingText') }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
const axios = require("axios").default;
import Swal from "sweetalert2";
export default {
  data() {
    return {
      loading: false,
      search: "",
      page: 1,
      from: 0,
      server_items_length: -1,
      dataTableOptions: { page: 1, itemsPerPage: 50 },
      dialog: false,
      editMode: null,
      items: [],
      companies: [],
      company_id:1,
      position_types: [],
      form: {},
      dialogHeaderText: ""
    };
  },
  computed: {
    screenHeight() {
      return window.innerHeight - 170;
    },
    headers() {
      return [
        { text: "#", value: "id", align: "center", width: 30 },
        { text: this.$t("code"), value: "code", width: 30, sortable: true },
        { text: this.$t("name"), value: "name_" + this.$i18n.locale },
        
        {
          text: this.$t("actions"),
          value: "actions",
          width: 50,
          align: "center"
        }
      ].filter(
        v =>
          v.value != "actions" ||
          this.$store.getters.checkPermission("position-update") ||
          this.$store.getters.checkPermission("position-delete")
      );
    }
  },
  methods: {
    handleInputs(event){
    event.preventDefault()
    axios.post('https://transliterate.from-to.uz/api/v1/transliterate',{
        "body": {
          "lang_from": "uz_latin",
          "lang_to": "uz_cyrillic",
          "text": this.form.name_uz_latin
        }    
      }
    )
    .then((response)=>{
      this.form.name_uz_cyril = response.data.result
      this.form.name_ru = response.data.result
      console.log(response.data.result)
    })
    .catch(err=>{
      console.log(err)
    })
  },


    updatePage($event) {
      this.getList();
    },
    updatePerPage($event) {
      this.getList();
    },

    getList() {
      this.loading = true;
      axios
        .post(this.$store.state.backend_url + "api/positions", {
          pagination: this.dataTableOptions,
          search: this.search
        })
        .then(response => {
          this.items = response.data.positions.data;
          this.from = response.data.positions.from;
          this.server_items_length = response.data.positions.total;

          this.companies = response.data.companies.map(v => {
            return { text: v.name, value: v.id };
          });
          this.company_id = response.data.companies[0].id
          this.position_types = response.data.position_types.map(v => {
            return { text: v["name_" + this.$i18n.locale], value: v.id };
          });
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },
    newItem() {
      if (this.$store.getters.checkPermission("position-create")) {
        this.dialogHeaderText = this.$t("position.newPosition");
        this.form = {
          id: Date.now(),
          company_id:this.company_id,
          name: "",
          name_uz_cyril:"",
          name_uz_latin:"",
          email: "",
          phone: "",
          position_type_id: "",
          
        };
        this.dialog = true;
        this.editMode = false;
        if (this.$refs.dialogForm){
          this.form.company_id = this.company_id
          this.$refs.dialogForm.resetValidation();
        }
      }
    },
    editItem(item) {
      if (this.$store.getters.checkPermission("position-update")) {
        this.dialogHeaderText = this.$t("position.updatePosition");
        this.formIndex = this.items.indexOf(item);
        this.form = Object.assign({}, item);
        this.dialog = true;
        this.editMode = true;
        if (this.$refs.dialogForm) this.$refs.dialogForm.resetValidation();
      }
    },
    save() {
      if (this.$refs.dialogForm.validate())
        axios
          .post(
            this.$store.state.backend_url + "api/positions/update",
            this.form
          )
          .then(res => {
            this.getList();
            this.dialog = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: toast => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });

            Toast.fire({
              icon: "success",
              title: this.$t("create_update_operation")
            });
          })
          .catch(err => {
            console.log(err);
          });
    }, //document-types
    deleteItem(item) {
      if (this.$store.getters.checkPermission("position-delete")) {
        const index = this.items.indexOf(item);
        Swal.fire({
          title: this.$t("swal_title"),
          text: this.$t("swal_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("swal_delete")
        }).then(result => {
          if (result.value) {
            axios
              .delete(
                this.$store.state.backend_url +
                  "api/positions/delete/" +
                  item.id
              )
              .then(res => {
                this.getList(this.page, this.itemsPerPage);
                this.dialog = false;
                Swal.fire("Deleted!", this.$t("swal_deleted"), "success");
              })
              .catch(err => {
                Swal.fire({
                  icon: "error",
                  title: this.$t("swal_error_title"),
                  text: this.$t("swal_error_text")
                  //footer: "<a href>Why do I have this issue?</a>"
                });
                console.log(err);
              });
          }
        });
      }
    }
  },
  mounted() {
    this.getList();
  }
};
</script>

<style scoped>
.inputs-position{
  position: relative;
}
.buts-reload{
  position: absolute;
  right: 2%;
  top: 40%;
}
</style>
